import arrowWhite from "../../images/button-arrow-white.png";
import externalLink from "../../images/external-link.png";
import cronexLogo from "../../images/cronex-logo.png";
import resanLogo from "../../images/resan-logo.png";
import close from "../../images/close-icon.png";
import star from "../../images/star-regular.png";
import starFilled from "../../images/star-filled.png";

export default (theme) => ({
  mobileNav: {
    backgroundColor: theme.palette.primary.main,
    position: "absolute",
    zIndex: 1120,
    padding: 12,
    top: 0,
    left: 0,
    right: 0,
    display: "none",
    opacity: 0,
    transition: ".3s ease-in-out opacity",
    borderRadius: "0 0 50% 50% / 3% 3%",
    boxShadow: "0 24px 26px rgba(0,0,0,0.16)",
    "&[open]": {
      display: "block",
      opacity: 1,
    },
    [theme.breakpoints.up("md")]: {
      display: "none !important",
    },
    "& .action-buttons": {
      position: "absolute",
      top: 18,
      right: 68,
      "& a": {
        fontSize: 0,
        lineHeight: 0,
        padding: 14,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        borderRadius: "50%",
        marginRight: 12,
        display: "inline-block",
      },
    },
    "& .actions": {
      display: "flex",
      color: theme.palette.primary.contrastText,
      justifyContent: "flex-end",
      flexDirection: "row",
      "& .close": {
        border: "none",
        padding: 0,
        width: 52,
        height: 52,
        borderRadius: "50%",
        top: 18,
        right: 16,
        textAlign: "center",
        fontSize: 6,
        lineHeight: 1.8,
        letterSpacing: ".4em",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        textTransform: "uppercase",
        paddingTop: 30,
        backgroundImage: "url(" + close + ")",
        backgroundPosition: "center top 6px",
        backgroundRepeat: "no-repeat",
        margin: "6px 4px 0 0",
      },
    },
    "& .top-level": {
      "& .nav-item": {
        position: "relative",
        "&[open]": {
          "& .nav-links": {
            display: "block",
            opacity: 1,
          },
          "& > a, & > button": {
            "&::after": {
              transform: "rotate(90deg)",
            },
          },
        },
        "& > a, & > button": {
          border: "none",
          outline: "none",
          backgroundColor: "transparent",
          display: "block",
          fontSize: 24,
          fontWeight: 700,
          letterSpacing: "-0.008em",
          lineHeight: 1,
          color: theme.palette.primary.contrastText,
          padding: 22,
          width: "100%",
          textAlign: "left",
          position: "relative",
          "&::after": {
            content: '""',
            width: 20,
            height: 20,
            display: "block",
            backgroundImage: "url(" + arrowWhite + ")",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            position: "absolute",
            right: 22,
            top: 24,
            transformOrigin: "10px 10px",
            transition: "transform .3s ease-in-out",
          },
        },
        "& .nav-links": {
          padding: 22,
          position: "absolute",
          top: "100%",
          zIndex: 2,
          transition: "opacity .3s ease-in-out",
          display: "none",
          opacity: 0,
          width: "100%",
          backgroundColor: theme.palette.primary.main,
          boxShadow:
            "12px 0 " +
            theme.palette.primary.main +
            ", -12px 0 " +
            theme.palette.primary.main +
            ", 0 24px 26px rgb(0 0 0 / 16%)",
          borderRadius: "0 0 50% 50% / 3% 3%",
          "& > a:not(.full-link), & > button:not(.full-link)": {
            outline: "none",
            display: "block",
            margin: "0 22px 20px",
            fontSize: 16,
            lineHeight: 1.5,
            letterSpacing: "0.016em",
            textDecoration: "none",
            color: theme.palette.primary.contrastText,
          },
        },
        "&.products": {
          "& .nav-links": {
            padding: "0 15px",
            paddingBottom: 20,
            "& .full-link": {
              "&:nth-of-type(1), &:nth-of-type(1) .image::before": {
                backgroundColor: "#307FE2",
              },
              "&:nth-of-type(2), &:nth-of-type(2) .image::before": {
                backgroundColor: "#00AD9F",
              },
              "&:nth-of-type(3), &:nth-of-type(3) .image::before": {
                backgroundColor: "#FF8200",
              },
            },
          },
        },
      },
    },
    "& a.full-link, & button.full-link": {
      backgroundColor: theme.palette.secondary.main,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "flex-end",
      textDecoration: "none",
      marginBottom: 15,
      border: "none",
      textAlign: "left",
      padding: 0,
      outline: "none",
      width: "100%",
      "&.resan": {
        backgroundImage: "url(" + resanLogo + ")",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top 12px left",
        backgroundColor: "#103073 !important",
        backgroundSize: 130,
        "& .image::before": {
          backgroundColor: "#103073 !important",
          backgroundImage: "url(" + externalLink + ")",
        },
        "& .gatsby-image-wrapper": {
          "& > div": {
            paddingBottom: "150px !important",
          },
        },
      },
      "&.cronex": {
        backgroundImage: "url(" + cronexLogo + ")",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top 12px left",
        backgroundColor: "#E0004D !important",
        backgroundSize: 130,
        "& .image::before": {
          backgroundColor: "#E0004D !important",
          backgroundImage: "url(" + externalLink + ")",
        },
        "& .gatsby-image-wrapper": {
          "& > div": {
            paddingBottom: "150px !important",
          },
        },
      },
      "& .content": {
        padding: "0 12px 18px 20px",
        "& span": {
          color: theme.palette.primary.main,
          display: "block",
          fontSize: 12,
          letterSpacing: "0.016em",
          lineHeight: 2,
          "&:nth-of-type(2)": {
            color: theme.palette.secondary.contrastText,
            fontWeight: 700,
            letterSpacing: "0.008em",
            lineHeight: 1.2,
            fontSize: 16,
          },
        },
      },
      "& .image": {
        fontSize: 0,
        position: "relative",
        flex: "0 0 135px",
        maxWidth: "135px",
        "& .gatsby-image-wrapper": {
          "& > div": {
            paddingBottom: "110px !important",
          },
        },
        "& img, & .gatsby-image-wrapper": {
          transition: "opacity .3s ease-in-out",
        },
        "&::before": {
          backgroundImage: "url(" + arrowWhite + ")",
          width: 40,
          height: 40,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          content: '""',
          display: "block",
          position: "absolute",
          bottom: 15,
          left: -2,
          zIndex: 1,
          backgroundColor: theme.palette.secondary.main,
        },
      },
      "&:hover .image img, &:hover .gatsby-image-wrapper, &:focus .image img, &:focus .gatsby-image-wrapper": {
        opacity: 0.8,
      },
    },
  },
  deepNav: {
    backgroundColor: theme.palette.primary.main,
    position: "absolute",
    zIndex: 25,
    padding: 12,
    display: "block",
    top: 0,
    right: 0,
    width: "100vw",
    left: "-100vw",
    boxShadow: "100vw 0 0 rgba(255,255,255,0.0)",
    zIndex: 100000,
    borderRadius: "0 0 50% 50% / 3% 3%",
    overflow: "hidden",
    "&[open]": {
      left: 0,
      boxShadow:
        "100vw 0 0 rgba(255,255,255,0.25), 0 24px 26px rgb(0 0 0 / 16%)",
      transition: "box-shadow .3s ease-in-out, left .75s ease-in-out",
    },
    [theme.breakpoints.up("md")]: {
      display: "none !important",
    },
    "& .actions": {
      display: "flex",
      color: theme.palette.primary.contrastText,
      justifyContent: "space-between",
      flexDirection: "row",
      marginBottom: 30,
      "& .back": {
        width: 52,
        height: 52,
        top: 18,
        left: 16,
        fontSize: 0,
        lineHeight: 1.8,
        backgroundImage: "url(" + arrowWhite + ")",
        backgroundPosition: "center",
        transform: "rotate(180deg)",
        backgroundRepeat: "no-repeat",
        border: "none",
        backgroundColor: "transparent",
        outline: "none",
      },
      "& .close": {
        border: "none",
        padding: 0,
        width: 52,
        height: 52,
        borderRadius: "50%",
        top: 18,
        right: 16,
        textAlign: "center",
        fontSize: 6,
        lineHeight: 1.8,
        letterSpacing: ".4em",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        textTransform: "uppercase",
        paddingTop: 30,
        backgroundImage: "url(" + close + ")",
        backgroundPosition: "center top 6px",
        backgroundRepeat: "no-repeat",
        margin: "6px 4px 0 0",
      },
    },
    "&#washroom": {
      "& .full-link, .image::before": {
        backgroundColor: "#307FE2",
      },
      "& .prod-cat-col": {
        borderColor: "#307FE2",
      },
    },
    "&#plumbing": {
      "& .full-link, .image::before": {
        backgroundColor: "#00AD9F",
      },
      "& .prod-cat-col": {
        borderColor: "#00AD9F",
      },
    },
    "&#pipes": {
      "& .full-link, .image::before": {
        backgroundColor: "#FF8200",
      },
      "& .prod-cat-col": {
        borderColor: "#FF8200",
      },
    },
    "& .prod-cat-col": {
      height: "auto",
      backgroundColor: theme.palette.primary.main,
      borderLeft: "30px solid",
      marginLeft: -12,
      paddingBottom: 20,
      minHeight: 760,
      "& .sub-nav": {
        padding: "20px 22px 0 30px",
        "& > a, & > button": {
          display: "block",
          borderBottom: "1px solid " + theme.palette.primary.contrastText,
          color: theme.palette.primary.contrastText,
          textDecoration: "none",
          marginBottom: 20,
          paddingBottom: 16,
          fontSize: 16,
          lineHeight: 1.5,
          letterSpacing: "0.016em",
          position: "relative",
          "&::after": {
            content: '""',
            width: 20,
            height: 20,
            display: "block",
            backgroundImage: "url(" + arrowWhite + ")",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            position: "absolute",
            right: 0,
            top: 0,
            transformOrigin: "10px 10px",
            transition: "transform .3s ease-in-out",
          },
        },
      },
    },
    "& a.full-link": {
      backgroundColor: theme.palette.secondary.main,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "flex-end",
      textDecoration: "none",
      marginBottom: 15,
      outline: "none",
      width: "100%",
      "&.resan": {
        backgroundImage: "url(" + resanLogo + ")",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top 12px left",
        backgroundColor: "#103073",
        "& .image::before": {
          backgroundColor: "#103073",
          backgroundImage: "url(" + externalLink + ")",
        },
      },
      "&.cronex": {
        backgroundImage: "url(" + cronexLogo + ")",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top 12px left",
        backgroundColor: "#E0004D",
        "& .image::before": {
          backgroundColor: "#E0004D",
          backgroundImage: "url(" + externalLink + ")",
        },
      },
      "& .content": {
        padding: "0 12px 18px 20px",
        "& span": {
          color: theme.palette.primary.contrastText + "CC",
          display: "block",
          fontSize: 12,
          letterSpacing: "0.016em",
          lineHeight: 2,
          "&:nth-of-type(2)": {
            color: theme.palette.secondary.contrastText,
            fontWeight: 700,
            letterSpacing: "0.008em",
            lineHeight: 1.2,
            fontSize: 16,
          },
        },
      },
      "& .image": {
        fontSize: 0,
        position: "relative",
        flex: "0 0 135px",
        maxWidth: "135px",
        "& .gatsby-image-wrapper": {
          "& > div": {
            paddingBottom: "110px !important",
          },
        },
        "& img, & .gatsby-image-wrapper": {
          transition: "opacity .3s ease-in-out",
        },
        "&::before": {
          backgroundImage: "url(" + arrowWhite + ")",
          width: 40,
          height: 40,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          content: '""',
          display: "block",
          position: "absolute",
          bottom: 15,
          left: -2,
          zIndex: 1,
          backgroundColor: theme.palette.secondary.main,
        },
      },
      "&:hover .image img, &:hover .gatsby-image-wrapper, &:focus .image img, &:focus .gatsby-image-wrapper": {
        opacity: 0.8,
      },
    },
  },
  brandLink: {
    display: "block",
    textAlign: "center",
    fontSize: 0,
    padding: "2px 12px",
    zIndex: 2,
    position: "relative",
    backgroundColor: "white",
  },
  topNav: {
    backgroundColor: "#19365B",
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    position: "relative",
    zIndex: 1000,
    '&[data-home="true"]': {
      backgroundColor: "transparent",
      position: "absolute",
      top: 25,
      left: 0,
      right: 0,
      zIndex: 10,
      "& .desktop-nav": {
        top: 20,
      },
    },
    "& .wrapper": {
      maxWidth: 1280,
      padding: 22,
      margin: "0 auto",
      position: "relative",
      "& .site-logo": {
        width: 158,
      },
      "& .menu-trigger": {
        border: "none",
        padding: 0,
        width: 52,
        height: 52,
        borderRadius: "50%",
        position: "absolute",
        top: 18,
        right: 16,
        textAlign: "center",
        fontSize: 6,
        lineHeight: 1.8,
        letterSpacing: ".4em",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        textTransform: "uppercase",
        [theme.breakpoints.up("md")]: {
          display: "none",
        },
        "& span": {
          display: "block",
          width: 28,
          height: 10,
          borderTop: "1px solid " + theme.palette.primary.contrastText,
          borderBottom: "1px solid " + theme.palette.primary.contrastText,
          margin: "6px auto 8px",
        },
      },
      "& .action-buttons": {
        position: "absolute",
        top: 18,
        right: 68,
        [theme.breakpoints.up("md")]: {
          right: 6,
          top: 22,
        },
        "& a": {
          fontSize: 0,
          lineHeight: 0,
          padding: 14,
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          borderRadius: "50%",
          marginRight: 12,
          display: "none",
          position: "relative",
          "&:nth-of-type(1)": {
            display: "inline-block",
          },
          [theme.breakpoints.up("sm")]: {
            display: "inline-block",
          },
          "& span": {
            position: "absolute",
            color: "white",
            fontSize: 8,
            lineHeight: 1,
            padding: 2,
            backgroundColor: "#E2004F",
            borderRadius: "50%",
            border: "1px solid white",
            width: 14,
            height: 14,
            textAlign: "center",
            top: 0,
            right: 0,
          },
        },
      },
    },

    "& .desktop-nav": {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
      display: "flex",
      position: "absolute",
      top: 22,
      left: "50%",
      transform: "translateX(-50%)",
      zIndex: 99,
      "& .nav-item": {
        margin: "0 30px",
        paddingBottom: 25,
        "&:hover": {
          "& > a, & > button": {
            borderBottom: 0,
            zIndex: 60,
            "&::before": {
              content: '""',
              backgroundColor: theme.palette.primary.main,
              borderRadius: 26,
              height: 52,
              position: "absolute",
              top: -9,
              left: "50%",
              transform: "translateX(-50%)",
              width: "calc(100% + 80px)",
              display: "block",
              boxShadow: "0 24px 26px rgba(0,0,0,0.16)",
              zIndex: -1,
            },
            "&::after": {
              content: '""',
              position: "absolute",
              width: 64,
              backgroundColor: theme.palette.primary.main,
              height: 64,
              boxShadow: "-4px 4px 1px rgba(0,0,0,0.16)",
              bottom: -12,
              transform: "rotate(-45deg) translateX(-50%)",
              left: "38%",
              transformOrigin: "center",
              zIndex: -1,
            },
          },
          "& .sub-menu": {
            opacity: 1,
            display: "flex",
          },
        },
        "& > a, & > button": {
          textDecoration: "none",
          fontSize: 18,
          fontWeight: 700,
          letterSpacing: "0.008em",
          lineHeight: 1,
          color: theme.palette.primary.contrastText,
          padding: "8px 0",
          display: "block",
          whiteSpace: "nowrap",
          position: "relative",
          borderBottom: "2px solid " + theme.palette.primary.contrastText,
        },
        "& .sub-menu": {
          position: "absolute",
          display: "none",
          opacity: 0,
          transition: "opacity .3s ease-in-out",
          zIndex: 50,
          width: 768,
          top: "calc(100% - 1px)",
          padding: "70px 68px 80px",
          backgroundColor: "white",
          borderRadius: 8,
          left: "50%",
          transform: "translateX(-50%)",
          boxShadow: "0 16px 24px rgba(0,0,0,0.36)",
          flexDirection: "row",
          alignItems: "flex-start",
          justifyContent: "space-between",
          "& a.full-link": {
            backgroundColor: theme.palette.secondary.main,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "flex-end",
            textDecoration: "none",
            outline: "none",
            width: "100%",
            "&.resan": {
              backgroundImage: "url(" + resanLogo + ")",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "top 12px left",
              backgroundColor: "#103073",
              backgroundSize: 130,
              "& .image::before": {
                backgroundColor: "#103073",
                backgroundImage: "url(" + externalLink + ")",
              },
            },
            "&.cronex": {
              backgroundImage: "url(" + cronexLogo + ")",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "top 12px left",
              backgroundColor: "#E0004D",
              backgroundSize: 130,
              "& .image::before": {
                backgroundColor: "#E0004D",
                backgroundImage: "url(" + externalLink + ")",
              },
            },
            "&.overview": {
              "& .image": {
                fontSize: 0,
                position: "relative",
                flex: "0 0 135px",
                maxWidth: "135px",
                "& .gatsby-image-wrapper": {
                  "& > div": {
                    paddingBottom: "210px !important",
                  },
                },
              },
            },
            "& .content": {
              padding: "0 12px 18px 20px",
              "& span": {
                color: theme.palette.primary.contrastText + "CC",
                display: "block",
                fontSize: 12,
                letterSpacing: "0.016em",
                lineHeight: 2,
                "&:nth-of-type(2)": {
                  color: theme.palette.secondary.contrastText,
                  fontWeight: 700,
                  letterSpacing: "0.008em",
                  lineHeight: 1.2,
                  fontSize: 16,
                },
              },
            },
            "& .image": {
              fontSize: 0,
              position: "relative",
              flex: "0 0 205px",
              maxWidth: "205px",
              "& .gatsby-image-wrapper": {
                "& > div": {
                  paddingBottom: "150px !important",
                },
              },
              "& img, & .gatsby-image-wrapper": {
                transition: "opacity .3s ease-in-out",
              },
              "&::before": {
                backgroundImage: "url(" + arrowWhite + ")",
                width: 40,
                height: 40,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                content: '""',
                display: "block",
                position: "absolute",
                bottom: 15,
                left: -2,
                zIndex: 1,
                backgroundColor: theme.palette.secondary.main,
              },
            },
            "&:hover .image img, &:hover .gatsby-image-wrapper, &:focus .image img, &:focus .gatsby-image-wrapper": {
              opacity: 0.8,
            },
          },
          "& .sub-nav": {
            "& > a, & > button": {
              fontSize: 16,
              color: theme.palette.secondary.main,
              marginBottom: 20,
              display: "block",
              letterSpacing: "0.016em",
              transition: ".3s ease-in-out color",
              textDecoration: "none",
              "&:hover, &:focus": {
                color: theme.palette.secondary.dark,
              },
            },
          },
          "& .overview-col": {
            flex: "0 0 50%",
            padding: "0 12px",
          },
          "& .nav-col": {
            flex: "0 0 37.5%",
            padding: "0 12px",
          },
          "&.product-sub-nav": {
            width: 1082,
            maxWidth: "calc(100vw - 48px)",
            flexWrap: "wrap",
            alignItems: "flexStart",
            justifyContent: "space-between",
            "& .link-wrap": {
              flex: "0 0 50%",
              padding: "0 12px",
              "& .image": {
                flex: "0 0 205px",
              },
            },
            "& .prod-cat-col": {
              flex: "0 0 33.333%",
              padding: "0 12px",
              "& .sub-nav": {
                padding: 20,
              },
              "& .image": {
                flex: "0 0 130px",
              },
              "&:nth-of-type(1) .full-link, &:nth-of-type(1) .full-link .image::before": {
                backgroundColor: "#307FE2",
              },
              "&:nth-of-type(2) .full-link, &:nth-of-type(2) .full-link .image::before": {
                backgroundColor: "#00AD9F",
              },
              "&:nth-of-type(3) .full-link, &:nth-of-type(3) .full-link .image::before": {
                backgroundColor: "#FF8200",
              },
            },
          },
        },
      },
    },
    "& .wishlist": {
      backgroundColor: "black",
      borderRadius: "50%",
      border: "none",
      outline: "none",
      position: "relative",
      padding: 0,
      fontSize: 10,
      fontFamily: theme.bodyFont,
      color: theme.palette.common.white,
      letterSpacing: "0.152em",
      fontWeight: 500,
      textTransform: "uppercase",
      lineHeight: 2.4,
      marginTop: 20,
      marginRight: 20,
      zIndex: 2,
      "&::after": {
        content: '""',
        width: 20,
        height: 19,
        backgroundImage: "url(" + star + ")",
        display: "inline-block",
        backgroundSize: "contain",
        marginLeft: 6,
        verticalAlign: "top",
      },
      "&.added": {
        "&::after": {
          backgroundImage: "url(" + starFilled + ")",
        },
      },
    },
  },
  topNavTheme: {},
});
