/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Provider } from "react-redux";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Helmet } from "react-helmet";
import TopNav from "./TopNav";
import Meta from "./Meta";
import "./layout.scss";
import Footer from "./Footer";
import Toast from "./Toast";
import GlobalAlert from "./GlobalAlert";
import { store } from "../app/store";

import CookieConsent from "react-cookie-consent";


const Layout = (props) => {
  /*
  * Sugar CRM tracking code
  *
  * Adapted to work in React from original JS provided by Sugar
  * @see https://support.sugarcrm.com/documentation/market/sugar_market_user_guide/web_tracking/#Enabling_Web_Tracking
  */
  var __sf_config = {
    customer_id:  98068,
    host:         "emails.thomasdudley.co.uk",
    ip_privacy:   0,
    subsite:      "af0bafd9-77fb-4010-8374-0810e9fa823b",
    __img_path:   "/web-next.gif?"
  };

  let sugar = function () {
    var n = 10;
    var r = 0;

    var i = function () {
      if (r < n) {
        r++;
        if (typeof window.frt !== "undefined") {
          console.log('Sugar frt fired with', __sf_config);
          window.frt(__sf_config);
        } else {
          setTimeout(function () { i(); }, 500);
        }
      }
    };
    i();
  };

  useEffect(() => {
    sugar();
  }, []);

  return (
    <>
      <Provider store={store}>
        <CssBaseline />
        <style>
          {
            "html,body{max-width:100%;overflow-x:hidden;}body{position:relative}img{max-width:100%;}.gatsby-image-wrapper{position:relative;}.gatsby-image-wrapper picture{position:absolute;top:0;right:0;bottom:0;left:0;}.mb-0{margin-bottom: 0 !important;"
          }
        </style>
        <Meta meta={props.meta} title={props.title} path={props.path} />
        <Helmet>
          <script
            type="text/javascript"
            async={true}
            src="https://embed.tawk.to/5ecfb83f8ee2956d73a57056/default"
            charSet="UTF-8"
            crossOrigin="*"
          ></script>

          {/* Sugar CRM */}
          <script
            type="text/javascript"
            async={true}
            src={"https://" + __sf_config.host + "/js/frs-next.js"}
          ></script>
        </Helmet>
        <TopNav home={props.path === "/" ? true : false} />
        <div
          style={{
            margin: `0 auto`,
            maxWidth: 1280,
            padding: `0 1.0875rem`,
          }}
        >
          <main>{props.children}</main>
          <Footer />
          <Toast />
        </div>
        <GlobalAlert />

        <CookieConsent
          location="bottom"
          buttonText="Accept"
          cookieName="myAwesomeCookieName2"
          style={{
            background: "#101c32",
            zIndex: "2000000001",
          }}
          buttonStyle={{
            color: "#101c32",
            fontSize: 14,
            backgroundColor: "white",
            padding: "10px 20px",
            borderRadius: 25,
            fontFamily: "prometo, sans-serif",
            fontWeight: "600",
            letterSpacing: "0.016em",
            boxShadow: "0 4px 6px rgba(0,0,0,0.16)",
          }}
          expires={150}
        >
          This website uses cookies to enhance the user experience.{" "}
        </CookieConsent>
      </Provider>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  meta: PropTypes.object.isRequired,
};

export default Layout;
